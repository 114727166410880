import React from "react"
import styled from "styled-components"

import mac from '../images/mac.png';


const Container = styled.div`
  position : relative;
  width    : 92.3rem;
  height   : 54.4rem;
  flex-basis : 92.3rem;
  flex-shrink : 0;
`;

const Screenshot = styled.div`
  position : absolute;
  width: 70.8rem;
  height: 44.2rem;
  top: 4rem;
  left: 10.7rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: white;
`;

const Frame = styled.img`
  position : absolute;
  width    : 92.3rem;
  height   : 54.4rem;
`;

export default ({src, ...props}) => {
  return (
    <Container {...props}>
      <Screenshot style={{backgroundImage : `url('${src}')`}} />
      <Frame src={mac} />
    </Container>
  );
}
