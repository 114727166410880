import React, { useState } from "react";
import styled from "styled-components"
import { colors } from '../shared/styles';

import { behaviourTagWrapper } from '../shared/analytics';

const SelectorContainer = styled.div`

`;

const SelectorItem = styled.div`
  font-size : 1.8rem;
  line-height : 4rem;
  cursor : pointer;

  &.selected {
    font-weight : 800;
    color : ${colors.blue};
  }

  &:hover {
    color : ${colors.blue};
  }
`;

const Point = styled.div`
  width : 0.8rem;
  height : 0.8rem;
  border-radius : 0.8rem;
  background-color : ${colors.pink};
  display : inline-block;
  margin-right : 2rem;

  transform : translateX(-30vw);
  opacity : 0;

  &.selected {
    transform : translateX(0%);
    opacity : 1;

  }

`
const Point1 = styled(Point)`
  transition : transform 200ms ease-in, opacity 200ms linear;

  &.selected {
    transition : transform 600ms ease-out, opacity 600ms linear;
  }
`
const Point2 = styled(Point)`
  transition : transform 400ms ease-out, opacity 400ms linear;
`
const Point3 = styled(Point)`
  transition : transform 600ms ease-in, opacity 600ms linear;

  &.selected {
    transition : transform 200ms ease-out, opacity 200ms linear;
  }
`


const Item = ({name, selected, onSelect}) => {
  const selectedClass = selected ? 'selected' : '';

  const analyticsOnSelect = behaviourTagWrapper('references-filter');

  return (
    <SelectorItem onClick={analyticsOnSelect(() => onSelect(!selected))} className={selectedClass} >
      <Point1 className={selectedClass} />
      <Point2 className={selectedClass} />
      <Point3 className={selectedClass} />
      {name}
    </SelectorItem>
  );
}


const Selector = ({categories = [], onSelect, ...props}) => {
  const [selected, setSelected] = useState({});

  return (
    <SelectorContainer {...props}>
      {
        categories.map(
          ({key, name}) =>
            <Item
              key={key}
              name={name}
              selected={!!selected[key]}
              onSelect={
                value => {
                  const newSelection = {...selected, [key] : value};
                  setSelected(newSelection);
                  onSelect(Object.entries(newSelection).filter(([k,v]) => !!v).map(([k,v]) => k));
                }
              }
              />
        )
      }
    </SelectorContainer>
  );
};

export default Selector;
