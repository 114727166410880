import React, { useState } from "react";
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { dimensions } from '../shared/styles';

import ScrollAnalytics from '../components/analytics/scrollanalytics';
import DotBackground from '../components/dotbackground';
import Frame from '../components/frame';
import MobileVideo from '../components/mobilevideo';
import MobileScreenshot from '../components/mobilescreenshot';
import LaptopVideo from '../components/laptopvideo';
import LaptopScreenshot from '../components/laptopscreenshot';
import TabletVideo from '../components/tabletvideo';
import ReferenceSelector from '../components/referenceselector.js';

import content from '../data/static-content.js';
import references, { categories } from '../data/references';

const PageContainer = styled.div`
  position : relative;
  overflow : hidden;
  &::-webkit-scrollbar{
    display : none;
  }
`;

const MainSection = styled.section`
  padding-top : ${dimensions.headerHeight};
  overflow : hidden;
  position : relative;
`;

const TitleContainer = styled.div`
  display : flex;
  fles-direction : row;

  @media screen and (max-width: 425px) {
    flex-direction : column-reverse;
  }
`;

const SelectorContainer = styled.div`
  position : relative;
  flex : 4;

  padding-top : 20rem;
  padding-left : 10%;

  @media screen and (max-width: 800px) {
    flex : 3;
  }

  @media screen and (max-width: 425px) {
    padding-top : 2rem;
    flex : 1;
  }
`;

const TitleFrameContainer = styled.div`
  flex : 6;
  display : flex;
  flex-direction : column;
  align-items : flex-start;

  @media screen and (max-width: 800px) {
    flex : 7;
  }

  @media screen and (max-width: 425px) {
    flex : 1;
    align-items : center;
  }
`;

const TitleFrame = styled(Frame)`
  z-index : 1
`;

const Description = styled.div`
  width : 50rem;
  border : 1px solid black;
  padding : 5.6rem;
  padding-top : 10rem;
  line-height : 3rem;
  font-size : 1.8rem;
  background-color : white;

  position : relative;
  top : -5rem;
  left : 20rem;

  @media screen and (max-width: 425px) {
    left : auto;
    width : 92%;
  }
`;

const MainSectionBackground = styled(DotBackground)`
  position : absolute;
  width : 60%;
  height : 60rem;

  &:nth-child(2n+0) {
    left : 15%;
  }

  &:nth-child(2n+1) {
    right : 15%;
  }

  &:nth-child(2) {top : 100rem;}
  &:nth-child(3) {top : 200rem;}
  &:nth-child(4) {top : 300rem;}
  &:nth-child(5) {top : 400rem;}
  &:nth-child(6) {top : 500rem;}
  &:nth-child(7) {top : 600rem;}
  &:nth-child(8) {top : 700rem;}
  &:nth-child(9) {top : 800rem;}
  &:nth-child(10) {top : 900rem;}
  &:nth-child(11) {top : 1000rem;}
  &:nth-child(12) {top : 1100rem;}
  &:nth-child(13) {top : 1200rem;}
  &:nth-child(14) {top : 1300rem;}
  &:nth-child(15) {top : 1400rem;}
  &:nth-child(16) {top : 1500rem;}
  &:nth-child(17) {top : 1600rem;}
  &:nth-child(18) {top : 1700rem;}
  &:nth-child(19) {top : 1800rem;}

`;

const ReferenceSection = styled.div`
  display : flex;
  flex-direction : row;
  padding-top : 10rem;
  padding-bottom : 10rem;

  &:nth-child(2n+0) .small-screenshot{
    top : -10rem;
    transform : scale(0.85) rotate(5deg);
  }

  &:nth-child(2n+1) .small-screenshot{
    bottom : -10rem;
    transform : scale(0.85) rotate(-10deg);
  }

  &:nth-child(2n+0) .tablet-video{
    transform : scale(0.7) rotate(5deg);
    @media screen and (max-width: 426px) {
      transform : scale(0.6);
    }
  }

  &:nth-child(2n+1) .tablet-video{
    transform : scale(0.7) rotate(-5deg);
    @media screen and (max-width: 426px) {
      transform : scale(0.6);
    }
  }

  @media screen and (min-width: 426px) {
    &:nth-child(2n+0) {
      flex-direction : row-reverse;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction : column;
  }

`;


const ReferenceDescriptionContainer = styled.div`
  flex : 1;
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;

  @media screen and (max-width: 425px) {
    margin-top : -10rem;
  }

`;

const ReferenceDescriptionFrame = styled(Frame)`
  width : 80%;

  & .content-container {
    padding-bottom : 5rem;
  }
`;

const ReferenceDescription = styled.p`
  line-height : 3rem;
  font-size : 1.8rem;
`;

const ReferenceHashTag = styled.div`
  display : inline-block;
  margin-right : 1.7rem;
  padding : 1rem 2rem;
  border : 3px solid #525DF4;
  background-color : rgba(82, 83, 244, 0.1);
  font-size : 1.8rem;
  font-weight : 800;
  color : #525DF4;
  margin-top : 3rem;
`;

const ResponsiveMobileVideo = styled(MobileVideo)`
  margin-left : 20%;

  @media screen and (max-width: 800px) {
    margin-left : 0%;
  }
`

const ReferenceScreenshotContainer = styled.div`
  flex : 1;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;
  position : relative;
`;

const SmallMobileScreenshot = styled(MobileScreenshot)`
  position : absolute;
  left : -5rem;
  flex : 0;
  flex-basis : 28.9rem;

  @media screen and (max-width: 800px) {
    display : none;
  }
`;

const ResponsiveMobileScreenshot = styled(MobileScreenshot)`
  margin-left : 20%;

  @media screen and (max-width: 800px) {
    margin-left : 0%;
  }
`;


const ResponsiveLaptopVideo = styled(LaptopVideo)`
  position : absolute;
  transform : scale(0.7);

  @media screen and (max-width : 1000px) {
    transform : scale(0.65);
  }

  @media screen and (max-width : 800px) {
    transform : scale(0.5);
  }

  @media screen and (max-width : 600px) {
    transform-origin: 40% 50%;
  }

  @media screen and (max-width : 425px) {
    position : relative;
    transform-origin: 50% 40%;
    /* transform-origin: center right; */
  }
`;

const ResponsiveLaptopImage = styled(LaptopScreenshot)`
  position : absolute;
  transform : scale(0.7);

  @media screen and (max-width : 1000px) {
    transform : scale(0.65);
  }

  @media screen and (max-width : 800px) {
    transform : scale(0.5);
  }

  @media screen and (max-width : 600px) {
    transform-origin: 40% 50%;
  }

  @media screen and (max-width : 425px) {
    position : relative;
    transform-origin: 50% 40%;
    /* transform-origin: center right; */
  }
`;

const ResponsiveTabletVideo = styled(TabletVideo)`
position : absolute;
transform : scale(0.7) rotate(-5deg);

@media screen and (max-width : 1000px) {
  transform : scale(0.65);
}

@media screen and (max-width : 800px) {
  transform : scale(0.5);
}

@media screen and (max-width : 600px) {
  transform-origin: 40% 50%;
  transform :
}

@media screen and (max-width : 425px) {
  position : relative;
  transform-origin: 50% 40%;
  transform :;
}
`;




const ReferencePage = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);

  const hasMatchingCategories = (a1, a2) => !!a1.filter(c => a2.includes(c)).length;

  const filteredReferences = selectedCategories.length ? references.references.filter(
    ({categories : refCat = []}) => hasMatchingCategories(selectedCategories, refCat)
  ) : references.references;

  return (
    <Layout>
      <SEO title="Appunto" />
      <PageContainer>
        {
          filteredReferences.map((r, index) => <MainSectionBackground key={index}/>)
        }
        <MainSection>
          <TitleContainer>
            <SelectorContainer>
              <ReferenceSelector
                categories={categories}
                onSelect={setSelectedCategories}
                />
            </SelectorContainer>
            <TitleFrameContainer>
              <TitleFrame>
                <h1>{content.references.title}</h1>
              </TitleFrame>
              <Description>{content.references.description}</Description>
            </TitleFrameContainer>
          </TitleContainer>
        </MainSection>
        {
          filteredReferences.map(
            reference =>
              <ReferenceSection key={reference.name}>
                <ReferenceScreenshotContainer>
                  {
                    reference.screenshots.type === 'mobile-image-video' ?
                      <>
                        <SmallMobileScreenshot
                          className="small-screenshot"
                          src={reference.screenshots.image}
                          />
                        <ResponsiveMobileVideo
                          src={reference.screenshots.video}
                          />
                      </> :
                    reference.screenshots.type === 'mobile-image-image' ?
                      <>
                        <SmallMobileScreenshot
                          className="small-screenshot"
                          src={reference.screenshots.imageSmall}
                          />
                        <ResponsiveMobileScreenshot
                          src={reference.screenshots.imageBig}
                          />
                      </> :
                    reference.screenshots.type === 'laptop-video' ?
                      <ResponsiveLaptopVideo
                        src={reference.screenshots.video}
                      />  :
                    reference.screenshots.type === 'laptop-image' ?
                      <ResponsiveLaptopImage
                        src={reference.screenshots.image}
                      />  :
                      reference.screenshots.type === 'tablet-video' ?
                      <ResponsiveTabletVideo
                        className="tablet-video"
                        src={reference.screenshots.video}
                      /> :
                      null
                  }
                </ReferenceScreenshotContainer>
                <ReferenceDescriptionContainer>
                  <ScrollAnalytics action={`scroll-references-${reference.shortName.replace(' ', '-').toLowerCase()}`}>
                    <ReferenceDescriptionFrame tl="pink" tr br bl>
                      <h2>{reference.name}</h2>
                      <ReferenceDescription>{reference.description}</ReferenceDescription>
                      <div>
                        {
                          reference.hashtags.map(
                            ht => <ReferenceHashTag key={ht}>#{ht}</ReferenceHashTag>
                          )
                        }
                      </div>
                    </ReferenceDescriptionFrame>
                  </ScrollAnalytics>
                </ReferenceDescriptionContainer>
              </ReferenceSection>
          )
        }
      </PageContainer>
    </Layout>
  );
}

export default ReferencePage
